@import '~antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,800&display=swap');
@import 'overrides.scss';
@import 'typographies.scss';

body {
  font-family: 'Open Sans', sans-serif !important;
  color: #222222 !important;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

/* Helpers */
.border-bottom {
  border-bottom: 1px solid #DFE3E8;
}

.styled-input {
  border: 1px solid #EBEBEB;
  border-radius: 8px;
  height: 57px;
  font-size: 16px;
}
