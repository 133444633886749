
.ant-form-item {
  margin-bottom: 0 !important;
}
.ant-input-affix-wrapper, .ant-picker {
  border: 1px solid #DFE3E8 !important;
  border-radius: 4px !important;

  .ant-input-affix-wrapper-lg, 
  .ant-picker-large {
    height: 48px;
  }
}

.ant-picker-input {
  input {
    font-size: 16px;
  }
}

.ant-btn-lg {
  border-radius: 8px !important;
}

.ant-dropdown-menu {
  border-radius: 8px !important;
  font-size: 15px !important;
  .ant-dropdown-menu-item {
    padding: 16px !important;
    min-width: 135px !important;
    font-size: 15px !important;
  }
}


.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #222222;
  border-color: #222222;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #222222;
  &::after {
    background-color: #222222;
  }
}

.ant-picker-panel-container .ant-picker-panels {
  flex-wrap: wrap;
}

.ant-picker-panel-container .ant-picker-panel {
  margin: 0 auto;
}

.react-datepicker-wrapper {
  width: 100%;
}